import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon';
import styles from './LanguageSelect.module.scss';

const LanguageSelect = ({ languages = [] }) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const select = useRef(null);

    useEffect(() => {
        useDocumentEvent('keydown', closeLanguageSelect);
        useDocumentEvent('focusin', closeLanguageSelect);
        useDocumentEvent('mousedown', closeLanguageSelect);
    }, []);

    const useDocumentEvent = (event, callback) => {
        document.addEventListener(event, callback);
        return () => document.removeEventListener(event, callback);
    };

    const closeLanguageSelect = (e) => {
        if (
            (select.current && !select.current.contains(e.target)) ||
            e.keyCode === 27
        ) {
            setIsExpanded(false);
        }
    };

    const classes = classNames(styles['LanguageSelect'], {
        [styles['LanguageSelect--IsExpanded']]: isExpanded,
    });

    return (
        <div className={classes} ref={select}>
            <button
                id={'header-language-select'}
                className={styles['LanguageSelect__Button']}
                onClick={() => setIsExpanded(!isExpanded)}
                aria-expanded={isExpanded}
                aria-controls={'header-language-list'}>
                <span className={styles['LanguageSelect__Title']}>
                    {t('LanguageSelect.language')}
                </span>
                <span className={styles['LanguageSelect__Icon']}>
                    <Icon
                        type={isExpanded ? 'chevronThin' : 'globe'}
                        dimensions={{ width: '15px', height: '15px' }}
                    />
                </span>
            </button>
            <ul
                id={'header-language-list'}
                className={styles['LanguageSelect__List']}
                aria-labelledby={'header-language-select'}
                aria-hidden={!isExpanded}>
                {languages.map((language, index) => (
                    <li
                        key={index}
                        className={styles['LanguageSelect__ListItem']}>
                        <a
                            href={language.href}
                            className={styles['LanguageSelect__Link']}>
                            {language.title}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

LanguageSelect.propTypes = {
    languages: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
        })
    ),
};

export default LanguageSelect;
