export const getFacilityIdByType = (items, type) => {
    if (!items) {
        return null;
    }

    for (const item of items) {
        if (item.type === type) {
            return item.id;
        }
    }

    return null;
};
