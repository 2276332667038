import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useMyPagesData } from '../../context/MyPagesContext';
import { useTranslation } from 'next-i18next';
import { getCookie } from 'cookies-next';
import { throttle } from '../../utils/helpers';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';

import Button from '../Button';
import Icon from '../Icon';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import styles from './MyPagesHeader.module.scss';

const MyPagesHeader = ({
    items = [],
    languages = [],
    shouldHideLogout = false,
    shouldShowNavigation = true,
    homePageUrl = '',
    loggedOutUrl = '',
}) => {
    const { t } = useTranslation();

    const lastScrollTop = useRef(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [hideHeader, setHideHeader] = useState(false);
    const [actUserName, setActUserName] = useState(null);
    const [userName, setUserName] = useState(null);

    let hasUnpaidInvoices = false;

    if (!!shouldShowNavigation) {
        const { customerInvoices: customerInvoicesState } = useMyPagesData();
        const [invoices] = customerInvoicesState;

        hasUnpaidInvoices = invoices.find((item) => item.paid === false);
    }

    useEffect(() => {
        function keyListener(e) {
            const listener = keyListenersMap.get(e.keyCode);
            return listener && listener(e);
        }

        document.addEventListener('keydown', keyListener);

        return () => document.removeEventListener('keydown', keyListener);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);

    const handleScroll = useCallback(() => {
        const scrollY = window.scrollY || document.documentElement.scrollTop;
        const scrollSpeed = scrollY - lastScrollTop.current;
        const isScrollingDown = scrollSpeed > 0 ? true : false;

        if (scrollY < 350) {
            setHideHeader(false);
        } else if (scrollSpeed >= 6 && isScrollingDown) {
            setHideHeader(true);
        } else if (scrollSpeed <= -6 && !isScrollingDown) {
            setHideHeader(false);
        }

        lastScrollTop.current = scrollY;
    }, [hideHeader]);

    useEffect(() => {
        const handleScrollThrottled = throttle(handleScroll, 100);
        window.addEventListener('scroll', handleScrollThrottled);

        return () => {
            window.removeEventListener('scroll', handleScrollThrottled);
        };
    }, [handleScroll]);

    useEffect(() => {
        const actUserNameCookie = getCookie('openid-act-user-name');
        const userNameCookie = getCookie('openid-user-name');

        setActUserName(actUserNameCookie);
        setUserName(userNameCookie);
    }, []);

    const closeMenu = () => {
        setIsExpanded(false);
    };

    const keyListenersMap = new Map([[27, closeMenu]]);

    const setNoScrolling = () => {
        if (!isExpanded) {
            document.body.classList.add('no-scrolling');
        } else {
            document.body.removeAttribute('class');
        }
    };

    const headerClassNames = classNames(styles['MyPagesHeader'], {
        [styles['MyPagesHeader--Hidden']]: hideHeader,
        [styles['MyPagesHeader--CustomerService']]: !!actUserName && !!userName,
    });

    return (
        <FocusTrap active={isExpanded}>
            <header className={headerClassNames}>
                <div className={styles['MyPagesHeader__Top']}>
                    <div className={styles['MyPagesHeader__Inner']}>
                        <a
                            className={styles['MyPagesHeader__Back']}
                            href={'https://www.jamtkraft.se'}>
                            <Icon
                                type={'arrowPrevious'}
                                dimensions={{ width: '15px', height: '12px' }}
                            />
                            {t('Header.backToJamtkraft.se')}
                        </a>
                        {!!languages.length && (
                            <LanguageSelect {...{ languages }} />
                        )}
                    </div>
                </div>
                {shouldShowNavigation && (
                    <>
                        <div className={styles['MyPagesHeader__Bottom']}>
                            <div className={styles['MyPagesHeader__Inner']}>
                                <a
                                    href={homePageUrl}
                                    className={styles['MyPagesHeader__Logo']}>
                                    <span className="sr-only">
                                        {t('Header.home')}
                                    </span>
                                </a>
                                <Navigation
                                    hasUnpaidInvoices={hasUnpaidInvoices}
                                    items={items}
                                />

                                <div className={styles['MyPagesHeader__Icons']}>
                                    {!shouldHideLogout && (
                                        <a
                                            href={loggedOutUrl}
                                            className={
                                                styles['MyPagesHeader__Logout']
                                            }>
                                            <Icon
                                                type={'profile'}
                                                dimensions={{
                                                    width: '23px',
                                                    height: '23px',
                                                }}
                                            />
                                            <span
                                                className={
                                                    styles[
                                                        'MyPagesHeader__ProfileText'
                                                    ]
                                                }>
                                                {t('Header.logout')}
                                            </span>
                                        </a>
                                    )}
                                    <Hamburger
                                        onClick={() => {
                                            setIsExpanded(!isExpanded);
                                            setNoScrolling();
                                        }}
                                        isExpanded={isExpanded}
                                        t={t}
                                    />
                                </div>
                                <div
                                    className={styles['MyPagesHeader__Button']}>
                                    {!shouldHideLogout && (
                                        <Button
                                            modifiers={['yellowShadow']}
                                            iconSize={'large'}
                                            iconDirection={'reverse'}
                                            icon={'profile'}
                                            type={'profile'}
                                            link={{
                                                title: t('Header.logout'),
                                                id: '',
                                                href: loggedOutUrl,
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <MobileNavigation
                            isOpen={isExpanded}
                            items={items}
                            languages={languages}
                        />
                    </>
                )}
            </header>
        </FocusTrap>
    );
};

MyPagesHeader.propTypes = {
    languages: PropTypes.array,
    tabs: PropTypes.array,
    items: PropTypes.array,
    buttons: PropTypes.array,
};

const Navigation = ({
    items = [],
    active = false,
    hasUnpaidInvoices = false,
}) => {
    return (
        <nav className={styles['MyPagesHeader__Navigation']}>
            <ul className={styles['MyPagesHeader__NavigationList']}>
                {items.map((item, index) => (
                    <ListItem
                        key={index}
                        active={active}
                        hasUnpaidInvoices={hasUnpaidInvoices}
                        {...item}
                    />
                ))}
            </ul>
        </nav>
    );
};

Navigation.propTypes = {
    items: PropTypes.array,
};

const ListItem = ({
    title = '',
    href = '',
    id = '',
    hasUnpaidInvoices = false,
    active = false,
}) => {
    const classes = classNames(styles['MyPagesHeader__NavigationLink'], {
        [styles['MyPagesHeader__NavigationLink--Active']]: active,
        [styles['MyPagesHeader__NavigationLink--Invoices']]:
            title === 'Fakturor' && hasUnpaidInvoices,
    });
    return (
        <li className={styles['MyPagesHeader__NavigationListItem']}>
            <a className={classes} href={href} aria-controls={id}>
                {title}
            </a>
        </li>
    );
};

const Hamburger = ({ onClick, isExpanded = false, t }) => {
    const toggleClasses = classNames(styles['MyPagesHeader__Toggle'], {
        [styles['MyPagesHeader__Toggle--Active']]: isExpanded,
    });

    const toggleBurgerText = isExpanded ? t('Header.close') : t('Header.menu');
    return (
        <button
            className={toggleClasses}
            onClick={onClick}
            aria-expanded={isExpanded}>
            <span className={styles['MyPagesHeader__Hamburger']}>
                <span className={styles['MyPagesHeader__Line']} />
                <span className={styles['MyPagesHeader__Line']} />
                <span className={styles['MyPagesHeader__Line']} />
            </span>
            <span className={styles['MyPagesHeader__ToggleText']}>
                {toggleBurgerText}
            </span>
        </button>
    );
};

Hamburger.propTypes = {
    searchUrl: PropTypes.string,
    isExpanded: PropTypes.bool,
};

const MobileNavigation = ({ isOpen = false, items = [], languages = [] }) => {
    if (!items.length) {
        return null;
    }

    const { t } = useTranslation();
    const hasLanguages = languages.length !== 0;

    const classes = classNames(styles['MyPagesHeader__MobileNavigation'], {
        [styles['MyPagesHeader__MobileNavigation--Open']]: isOpen,
    });

    return (
        <nav className={classes} aria-label="mobile navigation">
            <div className={styles['MyPagesHeader__MobileNavigationInner']}>
                <ul className={styles['MyPagesHeader__MobileNavigationList']}>
                    {items.map((item, index) => (
                        <MenuItem key={index} {...item} />
                    ))}
                </ul>
                {hasLanguages && (
                    <MobileNavigationBottom {...{ languages, t }} />
                )}
            </div>
        </nav>
    );
};

MobileNavigation.propTypes = {
    isOpen: PropTypes.bool,
    items: PropTypes.array,
    languages: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
        })
    ),
};

const MenuItem = ({ title = '', href = '' }) => {
    return (
        <li className={styles['MyPagesHeader__MobileNavigationItem']}>
            <a
                className={styles['MyPagesHeader__MobileNavigationLink']}
                href={href}>
                {title}
            </a>
        </li>
    );
};

MenuItem.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    columns: PropTypes.array,
    cards: PropTypes.array,
};

const MobileNavigationBottom = ({ languages = [], t }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const select = useRef(null);

    useEffect(() => {
        useDocumentEvent('keydown', closeLanguageSelect);
        useDocumentEvent('focusin', closeLanguageSelect);
        useDocumentEvent('mousedown', closeLanguageSelect);
    }, []);

    const useDocumentEvent = (event, callback) => {
        document.addEventListener(event, callback);
        return () => document.removeEventListener(event, callback);
    };

    const closeLanguageSelect = (e) => {
        if (
            (select.current && !select.current.contains(e.target)) ||
            e.keyCode === 27
        ) {
            setIsExpanded(false);
        }
    };

    const classes = classNames(styles['MyPagesHeader__LanguageSelect'], {
        [styles['MyPagesHeader__LanguageSelect--IsExpanded']]: isExpanded,
    });

    return (
        <div className={styles['MyPagesHeader__MobileNavigationBottom']}>
            <div className={classes} ref={select}>
                <button
                    className={styles['MyPagesHeader__LanguageButton']}
                    onClick={() => setIsExpanded(!isExpanded)}>
                    {t('Header.language')}
                    <Icon
                        type={isExpanded ? 'chevronThin' : 'globe'}
                        modifiers={
                            isExpanded
                                ? ['languageSelect', 'rotate180']
                                : ['languageSelect']
                        }
                        dimensions={{
                            width: '15px',
                            height: '15px',
                        }}
                    />
                </button>
                <ul className={styles['MyPagesHeader__LanguageList']}>
                    {languages.map((language, index) => (
                        <li
                            key={index}
                            className={
                                styles['MyPagesHeader__LanguageListItem']
                            }>
                            <a
                                className={
                                    styles['MyPagesHeader__LanguageLink']
                                }
                                href={language.href}>
                                {language.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

MobileNavigationBottom.propTypes = {
    languages: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
        })
    ),
};

export default MyPagesHeader;
